$(document).ready(function() {

    $('.email').nospam({ filterLevel: 'normal',replaceText: true  });
    $('.liame').nospam({ filterLevel: 'normal',replaceText: false  });
    var url = $("meta[name=url]").attr("content");
    //$('.sidenavinner').each(function(){ const ps = new PerfectScrollbar($(this)[0]); });

    //uncomment if the footer is fixed
    //var footerHeight = $('.footer').outerHeight();
    //if ($(window).width() > 672) {
    //    $("#main").css('margin-bottom', footerHeight);
    //}


    $('.faqq h3').click(function() {
        $(this).closest('.faqq').next('.faqa').toggleClass('open').slideToggle(200);
        $(this).toggleClass('open');
        return false;
    });

    $('.lazy').lazyLoad();
    $(window).on( 'scroll', function(){
        $('.lazy').lazyLoad();
    });

    $('.logoslider').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        responsive: [
            {
                breakpoint: 982,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    });

    $('.csfade').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        fade: true
    });

    $('.imgslider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500
    });

    $('.txtpanelslider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        responsive: [
            {
                breakpoint: 982,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    });
    $('.txtslider').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.csslider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        easing: 'easeInOutElastic'
    });

    $(".module").slice(0, 5).css('display', 'grid');
    if ($(".module:hidden").length != 0) {
        $("#load").show();
    }
    $("#load").on("click", function (e) {
        e.preventDefault();
        $(".module:hidden").slice(0, 4).css('display', 'grid');
        if ($(".module:hidden").length == 0) {
            $("#load").text("No more to view")
                .fadeOut("slow");
        }
    });

    /* MENU */
    $('.closebtn').click(function () {
        $('body').removeClass('modalopen');
        $('.openmenu').removeClass('menuon');
        $('.sidenavinner').hide();
        $('#sidenav').delay(500).css('width', '0px');
        return false;
    });
    $('.openmenu').on('click', function(e) {
        e.preventDefault();
        if ($(this).hasClass('menuon')) {
            $(this).removeClass('menuon');
            $('body').removeClass('modalopen');
            //$('body').removeAttr('class');
            $('.sidenavinner').hide();
            $('#sidenav').delay(500).css('width', '0px');
        } else {
            $(this).addClass('menuon');
            $('body').css("overflow","unset");
            $('body').addClass('modalopen');
            if ($(window).width() <= 768) {
                $('#sidenav').css('width', '100%');
            } else {
                $('#sidenav').css('width', '326px');
            }
            $('.sidenavinner').delay(20).fadeIn(2200);
        }
        return false;
    });

    $(".sidenav ul li.expandable").append('<span class="expander"></span>');
    $(".sidenav ul li.active.expandable span.expander").addClass('open');
    $('.expander').click(function() {
        $(this).toggleClass('open');
        $(this).closest('li.expandable').find('ul.submenu:first').slideToggle();
        return false;
    });

    // First-level submenu
    $('ul.mainmenu > li.expandable').on('mouseenter', function () {
        $(this).addClass('keepfocus');
        if ($(window).width() > 672) {
            $('#sidenav').css('width', '652px'); // Expand to show the second-level menu
            $('.sidenavmain').css('width', 'calc(326px * 2)');
            if ($(window).width() > 982) {
                $(this).find('> ul.submenu').css({ visibility: 'visible', opacity: 1 });
            }
        }
    }).on('mouseleave', function () {
        $(this).removeClass('keepfocus');
        if ($(window).width() > 672) {
            $('#sidenav').css('width', '326px'); // Collapse back to initial width
            $('.sidenavmain').css('width', '326px');
            if ($(window).width() > 982) {
                $(this).find('> ul.submenu').css({ visibility: 'hidden', opacity: 0 });
            }
        }
    });

    // Second-level submenu
    $('ul.mainmenu > li > ul.submenu > li.expandable').on('mouseenter', function () {
        $(this).addClass('keepfocus');
        if ($(window).width() > 982) {
            $('#sidenav').css('width', '978px'); // Expand to show the third-level menu
            $('.sidenavmain').css('width', 'calc(326px * 3)');
            $(this).find('> ul.submenu').css({ visibility: 'visible', opacity: 1 });
        }
    }).on('mouseleave', function () {
        $(this).removeClass('keepfocus');
        if ($(window).width() > 982) {
            $('#sidenav').css('width', '652px'); // Return to second-level menu width
            $('.sidenavmain').css('width', 'calc(326px * 2)');
            $(this).find('> ul.submenu').css({ visibility: 'hidden', opacity: 0 });
        }
    });

    $(window).resize(function () {
        if ($(window).width() <= 768) {
            $('.sidenavmain').css('width', '100%');
        } else {
            $('.sidenavmain').css('width', '300px');
        }
        return false;
    })















    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 150) {
            $('header').addClass('small');
        } else {
            $('header').removeClass('small');
        }
    });

    var lastScrollTop = 0;
    $(window).scroll(function(event){
        var st = $(this).scrollTop();
        if (st > lastScrollTop){
            $('header').addClass('scrolldown');
        } else {
            $('header').removeClass('scrolldown');
        }
        lastScrollTop = st;
    });

    $('a.hsectionlink').click(function() {
        var selected_section = $(this).attr('rel');
        $('html, body').animate({
            scrollTop: $(selected_section).offset().top
        }, 500);
    });

    $('p.scrolltop a').click(function(){
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });

    $('.tabs li').click(function(e) {
        e.preventDefault();
        $('.tabs li').removeClass('active');
        $(this).addClass('active');
        $('.tabbedcontent').hide();
        var selected_tab = $(this).find('a').attr('rel');
        $(selected_tab).fadeIn();
        return false;
    });

    $('a.scrolldown').click(function(event){
        event.preventDefault();
        $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 620);
        return false;
    });

    $('.vslider').slick({
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        vertical: true,
        verticalSwiping: true,
        appendDots: '.csvslider',
        appendArrows: '.csvslider'
    });

    var helpers = {
        addZeros: function (n) {
            return (n < 10) ? '0' + n : '' + n;
        }
    };

});

function scrollToID(id, speed) {
    var offSet = 0;
    var obj = $(id).offset();
    var targetOffset = obj.top - offSet;
    $('html,body').animate({ scrollTop: targetOffset }, speed);
}

$.fn.extend({
    lazyLoad : function() {
        $(this).each( function() {
            if( inViewPort( $(this) ) ) {
                $(this).addClass('in');
            } else {
                $(this).removeClass('in');
            }
        });
    }
});

function inViewPort(e) {
    // cache viewport height and object offset
    var windowBottom = $(window).scrollTop() + $(window).height(),
        objectBottom = e.offset().top + e.outerHeight() / 3,
        inView = false;

    // if the object is completely visible in the window
    if ( windowBottom >= objectBottom ){
        inView = true;
    }
    return inView;
}



